import * as Sentry from "@sentry/react";
import { useState, useEffect, lazy, useRef } from 'react'
import './App.css'
import { useQuery } from '@apollo/client'
import { VALIDATE_AUTH } from "./Graphql/Queries";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import gb from 'date-fns/locale/en-GB'
import Door from './Pages/Door'
import Loading from "./Components/Loading";
import Lobby from "./Pages/Lobby";

function App() {
  const pathname = window.location.pathname
  const [valid, setValid] = useState(()=>{
    return JSON.parse(localStorage.getItem('valid_auth'))
  })
  const [credentials, setCredentials] = useState(() =>{
    return JSON.parse(localStorage.getItem('credentials'))
  })
  const [logged, setLogged] = useState(()=>{
    return credentials && valid ? true : false
  })
  const [error, setError]= useState(null) // null || 'network' || 'technical'

    let renderCounter = useRef(0)
    renderCounter.current += 1

    const doorPathnames = ["/login","/signup", "/confirm", "/forgot-password", "/reset-password"]

    // Run Validate query onFirstMount
    const { refetch, loading } = useQuery(VALIDATE_AUTH, {// runs on firstMount and on signout, so no 'early' nuanced treatment needed
        onCompleted:(data)=>{
            localStorage.setItem('valid_auth', JSON.parse(data.validateAuth))
            setValid(data.validateAuth)
        },
        onError: (operationError)=>{
            // handleNetworknDefaultErrors not needed here since UX messaging will be at Login via setError. Prevents alerts bombing.
            operationError?.networkError ? setError('network') : setError('technical')
            setValid(false)
            setLogged(false)
            setCredentials(false)
        }
    })  

    const isDoor = doorPathnames.includes(pathname) // ugly but works while we figure out a way to handle expired session since last login
    
    // Get credentials from localStorage. Necessary for when other components change 'logged' by signing out, so that credentials are updated to falsy value
    // Refetch VALIDATE_AUTH query to update 'valid'
    useEffect(() => {
        if(renderCounter.current === 1){ return } //do not fetch from LS again on mount
        setCredentials(JSON.parse(localStorage.getItem('credentials')))
        // Re-runs useQuery(VALIDATE_AUTH) and so the Apollo Client refetches credentials from local storage, which were saved on userLogin
        refetch()        
        
    }, [logged, refetch]) //remove refetch from dependencies if its addition causes bugs

    /* 
    * Forces the app to wait for validation to complete before rendering.
    * Handles the case when the credentials have expired since last log in.
    * Should not be displayed before login in, only if user is trying to an inner url with old validation
    * And therefore avoids rendering with outdated 'valid_auth' that was saved on local storage during a previous session.
    */
    if(loading && !isDoor){
        return <Loading message={"Wait just for a moment while complete validation"}/>
    }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={gb}>
                {!logged ? (
                    <Door setLogged={setLogged} error={error} />
                  ) : (
                    <Lobby setValid={setValid} setLogged={setLogged} setCredentials={setCredentials} setError={setError}/>
                )}
        </LocalizationProvider>
    
)
}

export default App;
