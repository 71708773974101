import * as Sentry from "@sentry/react"
import { useState, useEffect, useMemo, useRef, Suspense } from 'react'
import { useQuery, useApolloClient } from "@apollo/client"
import { 
    createBrowserRouter, 
    createRoutesFromElements, 
    RouterProvider, 
    Route 
} from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { localStorageParse } from "../Components/methods/localStorageParse"
import Loading from "../Components/Loading"
import AuthRequired from "../Components/AuthRequired"


// ----------------------------------------------------------
import ErrorScreen from "../Pages/ErrorScreen"
import MapScreen from "../Pages/MapScreen"


    


 function Lobby(props) {
    // client
    const client = useApolloClient()

    const { setValid, setLogged, setCredentials, setError} = props

    const currentLocation = window.location.pathname
    const [shouldLogout, setShouldLogout] = useState(false) // controls centralized query errors sideEfects
   
    let renderCounter = useRef(0)
    renderCounter.current += 1

    let submittingRef = useRef(false)
    function logout(){

        client.resetStore()
        sessionStorage.clear()
        
        localStorage.removeItem('credentials')
        localStorage.removeItem('valid_auth')
        localStorage.removeItem('user')

        setLogged(false)
        setValid(false)
        setCredentials(false)
        
        return
    }
   

    const router =
        createBrowserRouter(createRoutesFromElements(
            // Layout
            <>            
                <Route path="/" element={<Navigate to="/home/map" />} />
                <Route 
                    path="/home" 
                    element={
                        <AuthRequired 
                            setLogged={setLogged} 
                            setValid={setValid}
                            setCredentials={setCredentials}
                        />
                    }
                    > 
                        <Route
                        path='/home/map'
                            element={<MapScreen
                                setShouldLogout={setShouldLogout}
                            />}
                        >                          
                    </Route>
                </Route>
                

                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="/confirm" element={<Navigate to="/" />} />
                <Route path="/signup" element={<Navigate to="/" />} />
                <Route path="/forgot-password" element={<Navigate to="/" />} />
                <Route path="/reset-password" element={<Navigate to="/" />} />
                <Route path="/onboarding/*" element={<Navigate to="/" />} />
                <Route path="*" element={<ErrorScreen code="404" />} />
            </>
        ))

    useEffect(()=>{
        //Centralized sideEffects to prevent duplication and/or navigation conflicts:
        // only runs when dep changes value, not for every setShouldLogout call
        if(shouldLogout){
            logout()

        }
        // eslint-disable-next-line
    },[shouldLogout])

    // if(loading || loadingPropertyData){
    //     return <div className="h-full w-full flex items-center justify-center"> <Loading /> </div>
    // }       
    
    return (
        <div className="min-h-screen h-full w-full">
            {/* <Suspense fallback={<Loading/>}> */}
            <Suspense fallback={<Loading/>}>
                <RouterProvider router={router}/>
            </Suspense>
        </div>
    )
}

export default Sentry.withProfiler(Lobby)