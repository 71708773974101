import React, { useRef, useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import Header from '../Components/Header'
import 'mapbox-gl/dist/mapbox-gl.css';
import { 
    Outlet,
    useOutletContext,
    useLocation,
    // useParams 
  } from 'react-router-dom'

function MapScreen(props) {
    const { 
        setShouldLogout
      } = props
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_MAP_TOKEN
    const mapContainer = useRef(null)
    const map = useRef(null)
    const [zoom, setZoom] = useState(4)
    const geolocateControl = useRef(null);
    const SATELLITE = 'mapbox://styles/homicity/clsgk2iuj03fw01o8hh9p0pc7'
    const NORMAL = 'mapbox://styles/homicity/clsaxlrdc02m801o816d61f27'

    // const [mapStyle, setMapStyle] = useState(NORMAL); // Default to streets-v11

    const [state, setState] = useState({
        mapStyle: NORMAL,
        thumbnail: 'satellite_map.png' 
    })
    const [mapCoordinates, setMapCoordinates] = useState({
        lat: 49.3295315, // Set initial latitude
        lng: -100.8049059, // Set initial longitude
    });

    const toggleMapStyle = () => {
        setState(prevState => ({
            ...prevState,
            mapStyle: state.mapStyle === NORMAL ?
            SATELLITE :
            NORMAL,      
            thumbnail: state.mapStyle === NORMAL ?
            'normal_map.png' :
            'satellite_map.png'
                     
        }))
    };

    

    const updateMapLocation = (lat, lng) => {
        setMapCoordinates({ lat: lat, lng: lng });
        map.current.flyTo({
            center: [lng, lat],
            zoom: 14,
            essential: true // This animation is considered essential with respect to prefers-reduced-motion
        });
    };

    // =========================================================================================== //
    //                                       - Side Effects - afterMount -                                      //
    // =========================================================================================== //
                                                  //|\\

    // 1. Map init
    useEffect(() => {
        if (map.current) return; // Initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/homicity/clrf5pq1z00ai01p41oxa6lvj',
            center: [mapCoordinates.lng, mapCoordinates.lat],
            zoom: zoom,
            showAttribution: false,
            attributionControl: false,
            satelliteToggle: true,
            countries: 'ca',
            proximity: 'ip'
        });


        geolocateControl.current = new mapboxgl.GeolocateControl({ // Set to memory for use in onload
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: true,
            showUserHeading: true,
        });
        map.current.addControl(geolocateControl.current);


        map.current.on('load', () => {
            geolocateControl.current.trigger(); // Ask for user location and center
        });
        
        

        // Clean up on unmount
        return () => {
            if (map.current && map.current.isStyleLoaded() && typeof map.current.remove === 'function') {
                map.current.remove();
            }
        };
    }, []);

    // Update map on pan or zoom
    useEffect(() => {
        if (!map.current) return; // Wait for map to initialize
        const handleMove = () => {
            const newLat = map.current.getCenter().lat.toFixed(4);
            const newLng = map.current.getCenter().lng.toFixed(4);
            const newZoom = map.current.getZoom().toFixed(2);
        
            // Only update state if values have changed significantly
            if (Math.abs(newLat - mapCoordinates.lat) > 0.01 || Math.abs(newLng - mapCoordinates.lng) > 0.01) {
                setMapCoordinates({
                    lat: newLat,
                    lng: newLng
                });
            }
        
            if (Math.abs(newZoom - zoom) > 0.01) {
                setZoom(newZoom);
            }
        };
        map.current.on('move', handleMove);

        return () => {
            map.current.off('move', handleMove); // Clean up event listeners
        };
    });

    useEffect(() => {
        if (!map.current) return; // Wait for map to initialize
        map.current.setStyle(state.mapStyle); // Update style when mapStyle changes
    }, [state.mapStyle]); 

    

    // Update map center when `mapCoordinates` changes
    // useEffect(() => {
    //     if (!map.current) return; // Wait for map to initialize
    //     map.current.flyTo({
    //         center: [mapCoordinates.lng, mapCoordinates.lat],
    //         essential: true // This animation is considered essential with respect to prefers-reduced-motion
    //     });
    // }, [mapCoordinates]);
    


    map?.current?.on('webglcontextlost', function(event) {
        console.error('WebGL context lost', event);
    });
    
    map?.current?.on('webglcontextrestored', function(event) {
        console.error('WebGL context restored', event);
    });

    return (
        <div className="flex flex-col h-screen overflow-y-hidden">
            <Header onLocationSelect={updateMapLocation} setShouldLogout={setShouldLogout}/>
            <div className="flex-grow max-h-[100%]" ref={mapContainer} lat={mapCoordinates.latitude} lng={mapCoordinates.longitude}>
                <button 
                    onClick={toggleMapStyle} 
                    style={
                        { 
                            position: 'absolute',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                            width: '50px',
                            height: '50px',
                            backgroundImage: `url('${state.thumbnail}')`,
                            backgroundSize: 'cover'
                        }
                    }
                    className='m-[12px] border-2 border-black rounded hover:border-4 hover:border-white'
                />
            </div>
        </div>
        
    )
}

export default MapScreen
