/**
 * 
 * @returns parsed value of the 'property' item from localStorage, or null if there is no item or if it is undefined
 */
export function localStorageParse(item){
    const localStorageItem = localStorage.getItem(item)
    if(!localStorageItem){ return null}
    if(localStorageItem === 'undefined' || localStorageItem === 'Undefined' ){
        return null
    }
    return JSON.parse(localStorageItem)

}