import { 
    useEffect, 
    useState,
    // useRef 
} from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { localStorageParse } from './methods/localStorageParse'
import { useLazyQuery, useApolloClient } from '@apollo/client'
import { VALIDATE_AUTH } from "../Graphql/Queries";
import Loading from './Loading';

export default function AuthRequired(props) {
    
    const client = useApolloClient()
    
    const location = useLocation()
    const navigate = useNavigate()

    const setLogged = props.setLogged
    const setValid = props.setValid
    const setCredentials = props.setCredentials


    const [isLoggedIn, setIsLoggedIn ] = useState(localStorageParse('valid_auth'))

    // const renderCounter = useRef(0)
    // renderCounter.current += 1

    const [ validateAuth, {loading}] = useLazyQuery(VALIDATE_AUTH, {
        onCompleted:(data)=>{
            if(!data.validateAuth){// if not valid, reset states so that <Navigate/> will redirect to Login
                
                client.resetStore()
                sessionStorage.clear()
                
                localStorage.removeItem('credentials')
                localStorage.removeItem('valid_auth')
                localStorage.removeItem('user')
                
                setLogged(false)
                setIsLoggedIn(false)
                setValid(false)
                setCredentials(false)
            }
        },
        onError: (operationError) =>{
            console.error('There was a problem while validating your credentials: ', operationError)
            // un-log the user
            setLogged(false)
            navigate("/login?message=There was a problem validating your credentials. Please try again later")
        }
    })

    useEffect(()=>{
        //check auth every time the user navigates to another route
        validateAuth()
    },[location.pathname, validateAuth]) //remove validateAuth from dependencies if its addition causes bugs

  if(loading){
    return <Loading message={"Wait just for a moment while complete validation"}/>
  }

  if(!isLoggedIn){
      return <Navigate to="/login?message=You must log in first" />
  }
    
  // if valid auth, renders onboarding or layout at this outlet
  return <Outlet/>
}
