import React from 'react'
import {ReactComponent as Icon} from '../parco-logo.svg'


export default function Loading(props) {
    const message = props.message
    return (
        <div className="flex h-[calc(100vh_-_18rem)] w-full flex-col items-center justify-center md:h-[calc(100vh_-_16rem)]">
            <div className='relative overflow-hidden'>
                {/* <Icon className='h-24 w-12 fill-blueGray-700 stroke-blueGray-700'/>
                <div className='absolute h-24 w-12 -translate-y-24 bg-white opacity-90 rounded animate-slide'></div>
                <Icon className='absolute h-24 w-12 fill-none stroke-blueGray-700 -translate-y-24'/> */}
                <Icon className='h-24 w-16 fill-blueGray-700 stroke-blueGray-700'/>
                <div className='absolute h-24 w-16 -translate-y-24 bg-white opacity-90 rounded animate-slide'></div>
                <Icon className='absolute h-24 w-16 fill-none stroke-blueGray-700 -translate-y-24'/>
            </div>            
            {/* Text */}
            <p className="max-w-xs text-center text-base font-normal text-[#293056]">
                { message
                    ? message 
                    : 'Wait just for a moment while we gather information about your property.'}
            </p>
            {/* </div> */}
        </div>
    )
}
