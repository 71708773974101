import { 
    createBrowserRouter, 
    createRoutesFromElements, 
    RouterProvider, 
    Route 
} from 'react-router-dom'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from 'react'
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, Navigate } from 'react-router-dom';
import SignUp from './SignUp'
import ResetPassword from './ResetPassword'
import ForgotPassword from './ForgotPassword'
import Login, {loader as loginLoader} from './Login'
import ErrorScreen from './ErrorScreen';

Sentry.init({
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),
    ],
    tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)


function Door(props) {

    const {setLogged, error} = props

    const message = error 
    ? (error === 'network' 
        ? 'No connection: Please try again when you have better network coverage'
        : 'We are experiencing technical difficulties at the moment. Please try again later or contact us if the issue persists'
        ) 
    : 'You must log in first'
    console.log("YEET")

    const router = sentryCreateBrowserRouter(createRoutesFromElements(
        <>
        <Route
            path="/"
            element={<Navigate to={error ? `/login?message=${message}` : "/login"}/>
            }
        />
        <Route
            path='/signup'
            element={                            
                <SignUp/>
            }
        />
        <Route
            path="/login"
            loader={loginLoader}
            element={<Login setLogged={setLogged}/>}
        />
        <Route path="/confirm" element={<Login setLogged={setLogged} />} />
        {/* This routes are deprecated */}
        {/* <Route 
            path="/dashboard/*" 
            element={
                <Layout
                    setLogged={setLogged}
                    onboard={false}
                />
            } 
        />
        <Route 
            path="/onboarding/*" 
            element={
                <Layout
                    setLogged={setLogged}
                    onboard={false}
                />
            } /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword setLogged={setLogged}/>} />
        <Route path="/home/*" element={<Navigate to={`/login?message=${message}`} />} />
        <Route path="/onboarding/*" element={<Navigate to={`/login?message=${message}`} />} />
        <Route path="*" element={<ErrorScreen code={404} />} />
        </>
    ))
    return (
        <div className='w-full'>            
            <RouterProvider router={router} />
        </div>
    )
}

export default Door
