import React, { Fragment, useState } from 'react'
import {ReactComponent as Icon} from '../parco-logo.svg'
import {
    Bars3Icon,
    ArrowLeftIcon
} from '@heroicons/react/24/outline'
import {
    Transition,
    Dialog,
} from '@headlessui/react'
import { SearchBox } from '@mapbox/search-js-react'

export default function Header(props) {
    const [open, setOpen] = useState(false)
    const { 
        setShouldLogout
      } = props

    function handleRetrieve(res) {
        const feature = res.features[0]
        props.onLocationSelect(feature?.geometry?.coordinates[1], feature?.geometry?.coordinates[0]);
    }

    return (
        <>
            <div className="sticky gap-x-4 flex flex-row justify-between items-center text-center bg-[#FFFFFF] w-full min-h-[100px] 3xl:min-h-[80px]  px-[4.75vw]">
                <div className='flex flex-row gap-x-4 items-center max-w-[700px] w-full max-h-[100px]'>
                    <a href="https://www.parco.cc/" target="_blank" rel="noopener noreferrer">
                        <Icon className='w-[75px] md:w-[115px] fill-blueGray-700 stroke-blueGray-700 '/>
                    </a>
                    
                    <form 
                        className="max-w-[500px] w-full"
                        onSubmit={event => event.preventDefault()} // prevent page refreshes onEnter.
                    >
                        <SearchBox // Use SearchBox instead of AddressAutofill
                            accessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
                            options={{
                                proximity: 'ip',
                                country: 'CA,US,GB',
                                language: 'en',
                                types: 'place,region,poi',
                                placeholder: 'SEARCH BY AREA OR LOCATION...'
                            }}
                            onRetrieve={handleRetrieve} // Use onSelect for SearchBox
                            placeholder='SEARCH BY AREA OR LOCATION...'
                            value=''
                            theme={{
                                variables: {
                                    fontFamily: 'Avenir, sans-serif',
                                    unit: '14px',
                                    padding: '0.5em',
                                    borderRadius: '4px',
                                    boxShadow: '0 0 0 1px silver',
                                    border: 'solid black 1px',
                                    colorText: '#000000',
                                    lineHeight:'15px',
                                    colorPlaceholder:'#000000',
                                    colour: '#000000',
                                    colourPrimary: '#00000'
                                }
                            }}
                        />
                        
                    </form>
                </div>
                <div className="inset-y-0 right-0 flex items-center lg:hidden">
                    <Bars3Icon
                        className="block h-[44px] w-[35px]"
                        onClick={() => setOpen(true)}
                    />
                </div>
                <div className='gap-x-8 h-full hidden lg:flex justify-between items-center font-urw_med text-sm tracking-custom leading-custom'>
                    <a href="https://www.parco.cc/">HOME</a>
                    <a href="https://www.explore.parco.cc/">EXPLORE</a>
                    <a href="https://www.parco.cc/elemental/">ELEMENTAL</a>
                    <a href="https://www.parco.cc/contact/">CONTACT</a>
                    <button onClick={() => setShouldLogout(true)}>logout</button>
                </div>
            </div>
            
            <Transition.Root show={open} as={Fragment} className=''>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-10 h-full" onClose={setOpen}>
                    {/* Overlay */}
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    {/* Sidebar Panel */}
                    <div className="fixed inset-0 h-full w-[80%] ml-auto">
                        <div className="absolute inset-0 h-full">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0 translate-x-full"
                                enterTo="opacity-100 translate-x-0"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100 translate-x-0"
                                leaveTo="opacity-0 translate-x-full"
                            >
                                <Dialog.Panel className="h-full w-full max-w-[413] absolute top-0">
                                <div className="h-full flex flex-col 
                                                justify-center items-center 
                                                bg-white shadow-xl overflow-y-hidden
                                                font-urw_bold text-lg tracking-custom 
                                                leading-custom py-[15px] gap-y-5">
                                    <button
                                        type="button"
                                        className='focus:ring-0'
                                        onClick={() => setOpen(false)}
                                    >
                                        <div className='flex flex-row justify-center items-center '>
                                            <ArrowLeftIcon className="block h-[20px] w-[20px] mr-[10px]"/>
                                            BACK TO MAPS
                                        </div>
                                    </button>
                                    <a href="https://www.parco.cc/">HOME</a>
                                    <a href="https://www.parco.cc/elemental/">ELEMENTAL</a>
                                    <a href="https://www.parco.cc/contact/">CONTACT</a>
                                    {/* Sidebar content */}
                                </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

        </>
    )
}