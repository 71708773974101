import { gql } from '@apollo/client'

export const SELF = gql`
    query userSelf{
        userSelf {
            email
            firstName
            id
            lastName
        }
    }
  
`

export const VALIDATE_AUTH = gql`
    {
        validateAuth
    }
`