import { gql } from '@apollo/client'

// define mutation for sign up
export const USER_SIGN_UP = gql`
    mutation userRegister(
        $firstName: String!
        $lastName: String!
        $email: String!
        $password: String!
        $passwordConfirmation: String!
        $confirmSuccessUrl: String!
    ) {
        userRegister(
            firstName: $firstName
            lastName: $lastName
            email: $email
            password: $password
            passwordConfirmation: $passwordConfirmation
            confirmUrl: $confirmSuccessUrl
        ) {
            #This is what is returned after the mutation happens
            authenticatable {
                firstName
                lastName
                email
                id
            }
            credentials {
                accessToken
                client
                expiry
                tokenType
                uid
            }
        }
    }
`
// define mutation to login
export const USER_LOGIN = gql`
    mutation userLogin($email: String!, $password: String!) {
        userLogin(email: $email, password: $password) {
            #This is what is returned after the mutation happens
            authenticatable {
                email
                firstName
                id
                lastName
            }
            credentials {
                accessToken
                client
                expiry
                tokenType
                uid
            }
        }
    }
`
// Mutation for sending email with password reset token
export const USER_SEND_PASSWORD_RESET_WITH_TOKEN = gql`
    mutation userSendPasswordResetWithToken(
        $email: String!
    ) {
        userSendPasswordResetWithToken(email: $email, redirectUrl: "homeprint.io") {
            message
        }
    }
`

export const USER_CONFIRM_ACCOUNT = gql`
    mutation userConfirmAccount($apiKey: String!, $token: String!) {
        userConfirmAccount(input: { apiKey: $apiKey, token: $token }) {
            success
        }
    }
`
// Mutation for resetting a users password given a valid token
export const USER_UPDATE_PASSWORD_WITH_TOKEN = gql`
mutation userUpdatePasswordWithToken(
    $password: String!
    $confirm_password: String!
    $token: String!
    ){
    userUpdatePasswordWithToken(
        password: $password,
        passwordConfirmation: $confirm_password,
        resetPasswordToken: $token
    ) {
        credentials {
            accessToken
            client
            expiry
            tokenType
            uid
        }
        authenticatable {
            email
            firstName
            id
            lastName
        }
    }
}
`